document.addEventListener("DOMContentLoaded", function() {
    const viewportWidth = window.innerWidth;
    const hoeheP = new HoehenMesser('.kontakt__kontakt');

    if (viewportWidth >= 1024) {
        hoeheP.abstandZumOberenRandMessen('p', 1);
        hoeheP.abstandZumOberenRandHinzufuegen('.kontakt__bild');
    }
    
    const kontaktDebounce = debounce(function() {
        
        if (viewportWidth >= 1024) {
        hoeheP.abstandZumOberenRandMessen('p', 1);
            hoeheP.abstandZumOberenRandHinzufuegen('.kontakt__bild');
        } else {
            const bildElement = document.querySelector('.kontakt__bild');
            if (bildElement) {
                bildElement.style.marginTop = null;
                console.log('Margin-top zurückgesetzt.');
            }
        }
    }, 250);

    kontaktDebounce();

    window.addEventListener('resize', kontaktDebounce);
});